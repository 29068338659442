import React, { useState, useEffect, Suspense, lazy } from 'react'
import getOr from 'lodash/fp/getOr'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { Helmet } from 'react-helmet'
import { MdAdd, MdFilterList } from 'react-icons/md'
import { useQuery } from '@apollo/react-hooks'

import { GET_ALL_EVENTS } from '../../graphql'
import { MyEvent, Event } from '../../types'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { darkGray } from '../../styles/themeColors'
import { SimpleLoader, VanityBox } from '../../components'
import { setCurrentEventId } from '../../features/siteSlice'
import {
  saveSelectedEvent,
  useGuestAcceptInvitacion,
  useIsUserAgent,
} from '../../common'

import { GroundZero } from './components'
import CreateEvent from './CreateEvent'
import { EventSelectorContainer } from './style'

import { EventSelector, Feed } from '.'

const BookedComponents = lazy(() => import('./components/BookedComponents'))
const RSVPcards = React.lazy(() => import('../Rsvp/RSVPcards'))

const SocialPanel: React.FC = () => {
  const isAgent = useIsUserAgent()
  const dispatch = useAppDispatch()
  const { currentEventId } = useAppSelector(state => state.site)
  const { updateGuestState } = useGuestAcceptInvitacion()
  const { data, loading } = useQuery(GET_ALL_EVENTS)
  const [showSection, setShowSection] = useState('posts')
  const [createEvent, setCreateEvent] = useState(false)
  const [modalCreateEvent, setModalCreateEvent] = useState(false)

  const myEvents: MyEvent[] = getOr([], 'me.myEventList', data)
  const events: Event[] = getOr([], 'me.eventList', data)

  useEffect(() => {
    const allevents = [...myEvents, ...events]
    if (allevents.length > 0 && !currentEventId) {
      // if there is no currentEventId, set the first event as the currentEventId
      dispatch(setCurrentEventId(allevents[0].id))
      saveSelectedEvent(allevents[0].id)
      updateGuestState(allevents[0].id)
    }
  }, [currentEventId, dispatch, events, myEvents, updateGuestState])

  if (loading) return <SimpleLoader />
  if (myEvents.length <= 0 && events.length <= 0) return <GroundZero />

  return (
    <>
      <Helmet>
        <title>Kiss & Tell | Social Panel</title>
        <meta name="description" content="Kiss & Tell Social Panel page" />
      </Helmet>
      <CreateEvent show={createEvent} onClose={() => setCreateEvent(false)} />
      <Modal show={modalCreateEvent} onHide={() => setModalCreateEvent(false)}>
        <Modal.Body>
          <EventSelector events={[...myEvents, ...events]} />
          <Button
            variant="outline-secondary"
            className="float-right"
            onClick={() => setModalCreateEvent(false)}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
      <EventSelectorContainer className="d-none d-md-block">
        <Container className="d-flex flex-wra">
          <EventSelector events={[...myEvents, ...events]} />
          {isAgent && (
            <Button
              variant="outline-primary"
              className="ml-auto mb-1 fwsb d-block"
              onClick={() => setCreateEvent(true)}
            >
              <MdAdd className="svg-top1" size={20} />
              Create Event
            </Button>
          )}
        </Container>
      </EventSelectorContainer>

      <Container className="main-social-panel">
        <div className="all-fwsb pt-3 pb-2 d-block d-md-none">
          <Button
            onClick={() => setShowSection('posts')}
            className={`py-1 mr-2 ${
              showSection === 'posts'
                ? 'btn-outline-salmon'
                : 'btn-outline-gray'
            }`}
          >
            FEED
          </Button>
          <Button
            onClick={() => setShowSection('events')}
            className={`py-1 mr-2
                  ${
                    showSection === 'events'
                      ? 'btn-outline-salmon'
                      : 'btn-outline-gray'
                  }
                `}
          >
            EVENTS
          </Button>
          <Button
            className="filter-button mr-2"
            onClick={() => {
              setModalCreateEvent(true)
              setShowSection('posts')
            }}
          >
            <MdFilterList color={darkGray} size={20} />
          </Button>
          {isAgent && (
            <Button
              onClick={() => setCreateEvent(true)}
              className="btn-outline-salmon py-1 float-right"
            >
              <MdAdd className="svg-top1" size={16} /> CREATE EVENT
            </Button>
          )}
        </div>
        <Row>
          <Col xs={12} className="pb-3 pt-1">
            {currentEventId && (
              <Suspense fallback={<SimpleLoader />}>
                <BookedComponents eventId={currentEventId} />
              </Suspense>
            )}
          </Col>
          <Col
            xs={12}
            md={4}
            lg={3}
            className={showSection !== 'events' ? 'hide-section' : 'display'}
          >
            <VanityBox myEvents={myEvents} />
            <Suspense fallback={<SimpleLoader />}>
              <RSVPcards myEvents={myEvents} otherEvents={events} />
            </Suspense>
          </Col>
          <Col
            xs={12}
            md={8}
            lg={9}
            className={showSection !== 'posts' ? 'hide-section' : 'display'}
          >
            <Feed myEvents={myEvents} otherEvents={events} />
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default SocialPanel
