import React from 'react'
import flow from 'lodash/fp/flow'
import split from 'lodash/fp/split'
import last from 'lodash/fp/last'
import Nav from 'react-bootstrap/Nav'
import DropdownItem from 'react-bootstrap/DropdownItem'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { MdSettings, MdPeople, MdEvent } from 'react-icons/md'
import {
  useRouteMatch,
  NavLink,
  Route,
  useLocation,
  Redirect,
} from 'react-router-dom'

import { IgenericObject } from '../../types'
import { useIsUserAgent } from '../../common'

import { Settings } from './Settings'
import { GuestList } from './GuestList'
import { EventBuilderPage as Builder } from './Builder'

export const Event: React.FC = () => {
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const isAgent = useIsUserAgent()

  const page = flow(split('/'), last)(pathname) as string
  const textMenu: IgenericObject = {
    guests: 'Guest List',
    settings: 'Settings',
    'event-builder': 'Event Builder',
  }

  return (
    <>
      <div className="bg-white pt-1 shadow-sm d-none d-sm-block">
        <Nav
          data-cy="event-settings"
          className="flex-column flex-sm-row container px-3"
        >
          <Nav.Item>
            <Nav.Link as={NavLink} to={`${url}/settings`}>
              <MdSettings size={16} /> EVENT SETTINGS
            </Nav.Link>
          </Nav.Item>
          {isAgent && (
            <Nav.Item>
              <Nav.Link as={NavLink} to={`${url}/event-builder`}>
                <MdEvent size={16} /> EVENT BUILDER
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link as={NavLink} to={`${url}/guests`}>
              <MdPeople size={16} /> GUEST LIST
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>

      {/* Mobile menu */}
      <div className="container d-block d-sm-none pt-3">
        <DropdownButton
          id="event-menu-mobile"
          title={textMenu[page]}
          variant="outline-primary"
        >
          <DropdownItem as={NavLink} to={`${url}/settings`}>
            Event Settings
          </DropdownItem>
          <DropdownItem as={NavLink} to={`${url}/event-builder`}>
            Event Builder
          </DropdownItem>
          <DropdownItem as={NavLink} to={`${url}/guests`}>
            Guest List
          </DropdownItem>
        </DropdownButton>
      </div>

      <Route exact path="/event/:eventId/guests" component={GuestList} />
      <Route exact path="/event/:eventId/settings" component={Settings} />
      <Route exact path="/event/:eventId/event-builder" component={Builder} />

      {/** when the url is just /event/:id the page is blank, so the default page is the event settings */}
      <Route exact path="/event/:eventId">
        <Redirect to={`${url}/settings`} />
      </Route>
    </>
  )
}
