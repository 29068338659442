import React from 'react'
import Container from 'react-bootstrap/Container'

import Zero from '../../../assets/images/zero/browser-listing.jpg'

export const GroundZero: React.FC = () => {
  return (
    <Container>
      <img
        data-cy="guide-image-gz"
        className="w-100 img-fluid mt-3 mt-md-5"
        src={Zero}
        alt="Ground Zero"
      />
    </Container>
  )
}
