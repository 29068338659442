import { useEffect, useState, RefObject } from 'react'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import snakeCase from 'lodash/fp/snakeCase'
import PasswordValidator from 'password-validator'
import { useMutation } from '@apollo/react-hooks'
import { useLocation, useHistory } from 'react-router-dom'

import { TRACK_VIEW } from '../graphql'
import { IParnershipTrack, IPasswordError, IuseReadToken, Role } from '../types'

import { getRefIds } from './storageFunctions'
import { useCurrentUser } from './user'

/**
 * @useOnScreen
 * This customHook function called useOnScreen takes a reference to an HTML element
 * and returns a boolean value indicating whether that element is in the visible screen or not.
 */
export const useOnScreen = (ref: RefObject<HTMLElement>): boolean => {
  // The isIntersecting state is used to track whether the element is on screen or not.
  const [isIntersecting, setIntersecting] = useState(false)
  useEffect(() => {
    // We create a new intersection observer.
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    )

    // If the current reference (ref.current) is not null, we observe the referenced element.
    if (ref.current) {
      observer.observe(ref.current)
    }
  }, [ref])

  // We return the value of isIntersecting, which indicates if the element is on screen or not.
  return isIntersecting
}

/**
 * @useValidatePassword
 * This custom hook function called useValidatePassword takes a password string and
 * returns a list of errors indicating what is wrong with the password.
 */
export const useValidatePassword = (password: string): IPasswordError[] => {
  const [validate, setValidate] = useState<IPasswordError[]>([])

  useEffect(() => {
    const schema = new PasswordValidator()
    const passwordRules = schema
      .is()
      .min(8)
      .has()
      .uppercase(1)
      .has()
      .digits(1)
      .has()
      .symbols(1)

    setValidate(
      passwordRules.validate(password, { details: true }) as IPasswordError[]
    )
  }, [password])

  return validate
}

/**
 * @useIsMobile
 * This custom hook function called useIsMobile returns a boolean value
 * indicating whether the device is a mobile device or not
 */
export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    // ua = User Agent
    const ua = navigator.userAgent.toLowerCase()
    const isMobileDevice = ua.includes('mobile') || ua.includes('android')
    setIsMobile(isMobileDevice)
  }, [])

  return isMobile
}

/**
 * @useReadToken
 * This custom hook takes a jwtToken string and
 * returns the header, payload and signature of the token
 * BUT it doesn't validate the token signature; it is only used to read the token content
 */
export const useReadToken = (jwtToken: string): IuseReadToken => {
  let header: Record<string, unknown> | undefined
  let payload: Record<string, unknown> | undefined

  const parts = jwtToken.split('.')

  // jwt token must have 3 parts
  if (parts?.length === 3) {
    header = JSON.parse(atob(parts[0]))
    payload = JSON.parse(atob(parts[1]))
  }

  return {
    header,
    payload,
    signature: get('[2]', parts),
  }
}

/**
 * @useTrackView
 * This custom hook takes an eventId, guestId and source string and
 * returns a function that tracks the view
 */
interface ITrackView {
  eventId?: string
  guestId?: string
  source: string
  destination: string
}
export const useTrackView = (): {
  track: ({ eventId, guestId, source, destination }: ITrackView) => void
  loading: boolean
} => {
  const [trackView, { loading }] = useMutation(TRACK_VIEW)

  const track = ({ eventId, guestId, source, destination }: ITrackView) => {
    // we use sessionStorage to prevent multiple insert with each rerender
    // sessionStorage is cleared automatically when the tab is closed
    const sourceSC = snakeCase(source)
    if (sessionStorage.getItem(`track-${sourceSC}`) || loading) return
    sessionStorage.setItem(`track-${sourceSC}`, 'true')

    const variables = {
      guestId,
      eventId,
      source,
      destination,
    }
    trackView({ variables })
      .then(() => {
        // this is a background task, so we don't need to do anything here
        // if we need to do something in the future, we can add it here
      })
      .catch(() => {
        // same here but this is to prevent a crash
      })
  }

  return { track, loading }
}

/**
 * @usePartnership
 * This custom hook is to handle everything related to the partnerships
 */
export const usePartnership = (): {
  getPartnershipData: ({
    cta,
    source,
  }: {
    cta: string
    source: string
  }) => string | undefined
} => {
  const getPartnershipData = ({
    cta,
    source,
  }: {
    cta: string
    source: string
  }): string | undefined => {
    const refIds = getRefIds()
    // to return undefined instead of null that way apollo will not send the field "partnership" in the mutation
    if (!refIds || refIds.length <= 0) return undefined

    return flow(
      JSON.parse,
      map((refId: IParnershipTrack) => ({
        ...refId,
        cta,
        currentUrl: source,
      }))
    )(refIds)
  }

  return {
    getPartnershipData,
  }
}

/**
 * @useUpdateUrlParam
 * Custom hook to update a url param
 * @returns (key: string, value: string | number) => void
 *
 * @example
 * const updateUrlParam = useUpdateUrlParam()
 * updateUrlParam('key', 'value')
 * updateUrlParam('key', 1)
 */
export const useUpdateUrlParam = (): ((
  key: string,
  value: string | number
) => void) => {
  const history = useHistory()
  const location = useLocation()

  const updateUrlParam = (key: string, value: string | number): void => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(key, value.toString())
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  return updateUrlParam
}

/**
 * @useIsUserAgent
 * Custom hook to check if the current user is an agent/admin
 * @returns boolean
 */
export const useIsUserAgent = (): boolean => {
  const { role } = useCurrentUser()
  return role === Role.Admin || role === Role.Concierge
}
