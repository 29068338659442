import React, { useState, Dispatch, SetStateAction } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import CreateEvent from '../../pages/SocialPanel/CreateEvent'
import { useCurrentUser, useIsUserAgent } from '../../common'

import { HowWeWorkBody } from './components'
import { Caret } from './style'

export const HowItWorks: React.FC<{
  open: boolean
  handle: Dispatch<SetStateAction<boolean>>
}> = ({ open, handle }) => {
  const { isAuthenticated } = useCurrentUser()
  const isAgent = useIsUserAgent()
  const [createEvent, setCreateEvent] = useState(false)
  const [registerModal, openRegisterModal] = useState(false)

  const handleCTA = (ev: React.MouseEvent) => {
    ev.preventDefault()
    if (isAuthenticated) {
      setCreateEvent(true)
    } else {
      openRegisterModal(true)
    }
  }

  if (createEvent) {
    return (
      <CreateEvent
        show={createEvent}
        onClose={() => {
          handle(false)
          setCreateEvent(false)
        }}
      />
    )
  }

  return (
    <Modal show={open} onHide={() => handle(false)} className="howItWorksModal">
      <Modal.Header closeButton style={{ border: 'none' }} className="pb-0">
        <Modal.Title className="text-primary text-center w-100 fwb fz-24 pt-2">
          Let Us Make{' '}
          <span className="d-block d-md-inline-block pt-2 pt-md-0">
            Your <Caret className="font-lf">Big</Caret> Day
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <HowWeWorkBody
          openRegisterModal={openRegisterModal}
          registerModal={registerModal}
        >
          {isAgent && (
            <Button onClick={handleCTA} className="px-5 fz-16 mx-auto d-block">
              Get Started
            </Button>
          )}
        </HowWeWorkBody>
      </Modal.Body>
    </Modal>
  )
}
