import React, { ChangeEvent, FormEvent, useState } from 'react'
import get from 'lodash/fp/get'
import trim from 'lodash/fp/trim'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { CREATE_GUEST, GET_MY_EVENT } from '../graphql'
import { MyEvent } from '../types'

export const Emails: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const [selectedFile, setSelectedFile] = useState<File>()
  const { data } = useQuery(GET_MY_EVENT, { variables: { id: eventId } })
  const [handleMutation] = useMutation(CREATE_GUEST)

  const myEvent: MyEvent = get('me.myEventInfo', data) || {}

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault()
    if (!selectedFile) return

    const reader = new FileReader()
    reader.readAsText(selectedFile)
    reader.onload = async () => {
      const jsonFile = JSON.parse(reader.result as string) || []

      for (let i = 0; i < jsonFile.length; i++) {
        const guest = jsonFile[i]
        const [firstName = '', lastName = ''] = guest.name?.split(' ') || []
        const variables = {
          eventId,
          tierIds: [],
          input: {
            canPost: false,
            firstName: trim(firstName),
            lastName: trim(lastName || firstName),
            email: trim(guest.email),
            phone: `${trim(guest.phone)}`,
            kidsAllowed: myEvent.defaultKidsAllowed || false,
            plusOne: myEvent.defaultPlusOne || 0,
          },
        }
        // eslint-disable-next-line no-await-in-loop
        const resp = await handleMutation({ variables })
        const status =
          resp.data.createGuest.errors.length <= 0
            ? 'created'
            : 'error creating'
        // eslint-disable-next-line no-console
        console.log(
          `${status} ${guest.email} -  ${i + 1} of ${jsonFile.length}`,
          resp
        )
      }
    }
  }

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0])
    }
  }

  if (!data) return <Container className="pt-4">Event not found</Container>
  return (
    <Container className="pt-4">
      <h5>{myEvent.name}</h5>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          required
          onChange={handleFileInputChange}
          accept="application/json"
        />
        <Button type="submit">Send</Button>
      </form>
    </Container>
  )
}
