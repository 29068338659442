import React, { useState, FormEvent, useRef } from 'react'
import getOr from 'lodash/fp/getOr'
import set from 'lodash/fp/set'
import isEmpty from 'lodash/fp/isEmpty'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import FormCheck from 'react-bootstrap/FormCheck'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useMutation } from '@apollo/react-hooks'
import { MdAdd, MdMoreHoriz, MdCancel } from 'react-icons/md'

import Tiers from '../../components/Tiers'
import { Textarea } from '../../components'
import {
  Error,
  FeedQuery,
  Maybe,
  MyEvent,
  Event,
  IGenericErrorObject,
  IcreatePostVariables,
  PollOptionInput,
  Tier,
  PostInput,
} from '../../types'
import { useForm } from '../../common/alterForms'
import { CREATE_FEED_POST, GET_EVENT_FEEDS } from '../../graphql'
import {
  isInstagramReelUrl,
  isYouTubeUrl,
  shortenLink,
  simpleAlert,
} from '../../common'
import { useAppSelector } from '../../hooks'
import { darkGray } from '../../styles/themeColors'

import { PollComposer, SMSpreview } from './components'

const Composer: React.FC<{ event: MyEvent | Event }> = ({ event }) => {
  const { currentEventId } = useAppSelector(state => state.site)
  const bodyRef = useRef<HTMLTextAreaElement>(null)
  const smsRef = useRef<HTMLInputElement>(null)
  const [tierIds, updateTierIds] = useState<string[]>([])
  const [deadline, setDeadline] = useState<string>()
  const [smsPreview, setSmsPreview] = useState(false)
  const initModel = { poll: undefined }
  const { errors, pushError, reset } = useForm(initModel)
  const [postInput, setPostInput] = useState<PostInput>()

  // Poll config
  const [showPollComposer, setShowPollComposer] = useState(false)
  const [addYoutubeVideo, setAddYoutubeVideo] = useState(false)
  const [addInstagramVideo, setAddInstagramVideo] = useState(false)
  const [invalidVideoUrl, setInvalidVideoUrl] = useState(false)
  const [incompletePoll, setIncompletePoll] = useState(true)
  const [multi, setMulti] = useState(false)
  const [options, setOptions] = useState<PollOptionInput[]>([])
  const [addPost, { loading }] = useMutation(CREATE_FEED_POST, {
    update(cache, { data: { createPost } }) {
      if (createPost.errors.length === 0) {
        const cached: Maybe<FeedQuery> = cache.readQuery({
          query: GET_EVENT_FEEDS,
          variables: { eventIds: [currentEventId] },
        })
        if (cached) {
          const posts = getOr([], 'feed.entries', cached)
          cache.writeQuery({
            query: GET_EVENT_FEEDS,
            variables: { eventIds: [currentEventId] },
            data: set('feed.entries', [createPost.result, ...posts], cached),
          })
        }
      }
    },
  })

  const handleCreatePost = (e: FormEvent) => {
    e.preventDefault()
    const body = bodyRef.current?.value
    if (loading || !body) return

    const regex = /https?:\/\/[^\s/$.?#].[^\s]*/g
    const modifiedBody = body.replace(regex, shortenLink)

    const videoInput = document.getElementById('videoUrl') as HTMLInputElement
    if (addInstagramVideo && !isInstagramReelUrl(videoInput?.value)) {
      // is not a valid instagram reel url
      setInvalidVideoUrl(true)
      return
    }
    if (addYoutubeVideo && !isYouTubeUrl(videoInput?.value)) {
      // is not a valid youtube url
      setInvalidVideoUrl(true)
      return
    }

    const variables: IcreatePostVariables = {
      input: {
        body: modifiedBody,
        poll: undefined,
        video: videoInput?.value,
        sms: smsRef.current?.checked || false,
      },
      eventId: event.id,
      tierIds,
    }

    if (showPollComposer && !incompletePoll) {
      variables.input.poll = { multi, options }
      variables.input.deadline = deadline
    }

    if (smsRef.current && smsRef.current.checked) {
      setPostInput(variables.input as PostInput)
      setSmsPreview(true)
      return // prevent post creation to allow sms preview modal handling
    }

    addPost({ variables })
      .then(({ data }) => {
        if (data.createPost.errors.length > 0) {
          data.createPost.errors.forEach(({ key, message }: Error) =>
            pushError(message, key)
          )
        } else {
          reset({ poll: undefined })
          updateTierIds([])
          setShowPollComposer(false)
          if (bodyRef.current) bodyRef.current.value = ''

          // reset video input
          setAddInstagramVideo(false)
          setAddYoutubeVideo(false)
          setInvalidVideoUrl(false)
          if (videoInput) videoInput.value = ''
        }
        setSmsPreview(false)
      })
      .catch(() => {
        simpleAlert({
          html: 'An error occurred while creating the post',
          icon: 'error',
        })
      })
  }

  const resetVideoInput = () => {
    setInvalidVideoUrl(false)
    const videoInput = document.getElementById('videoUrl') as HTMLInputElement
    if (videoInput) videoInput.value = ''
  }

  const handleAddPoll = () => {
    setShowPollComposer(true)
    setAddYoutubeVideo(false)
    setAddInstagramVideo(false)
    resetVideoInput()
  }

  const handleAddInstagramVideo = () => {
    setShowPollComposer(false)
    setAddInstagramVideo(true)
    setAddYoutubeVideo(false)
    resetVideoInput()
  }

  const handleAddYoutubeVideo = () => {
    setShowPollComposer(false)
    setAddInstagramVideo(false)
    setAddYoutubeVideo(true)
    resetVideoInput()
  }
  const cancelVideo = () => {
    setAddInstagramVideo(false)
    setAddYoutubeVideo(false)
    setInvalidVideoUrl(false)
    resetVideoInput()
  }

  const eventTiers: Tier[] = getOr([], 'tiers', event)
  return (
    <Card
      className="shadow-sm rounded mb-3"
      data-testid="composer-form-container"
    >
      <Card.Header className="px-2 py-1">
        <span>Create post</span>
        <div className="float-right d-flex">
          <label className="user-select-none mx-2" htmlFor="send-sms">
            Send SMS
          </label>
          <FormCheck
            ref={smsRef}
            custom
            id="send-sms"
            type="checkbox"
            defaultChecked={false}
          />
        </div>
      </Card.Header>

      <Form data-testid="composer-form" onSubmit={handleCreatePost}>
        <Textarea
          refInput={bodyRef}
          className="componer-error-textarea"
          border="none"
          error={errors as IGenericErrorObject}
          name="body"
          placeholder="Go ahead and select an event above and post a message for your group! Communicate with everyone or select a sub list to target specific guests."
        />

        {showPollComposer && (
          <PollComposer
            error={!isEmpty(errors.poll)}
            setOptions={setOptions}
            setMulti={setMulti}
            setShowPollComposer={setShowPollComposer}
            incompletePoll={setIncompletePoll}
            deadline={deadline}
            setDeadline={setDeadline}
          />
        )}

        {(addYoutubeVideo || addInstagramVideo) && (
          <div className="p-3 position-relative">
            <MdCancel
              color={darkGray}
              size={25}
              className="cursor position-absolute"
              onClick={cancelVideo}
              style={{ right: 10, top: 10 }}
              title="Cancel"
            />
            <label className="d-block mb-1" htmlFor="videoUrl">
              {addYoutubeVideo ? 'YouTube' : 'Instagram'} Video URL
              {invalidVideoUrl && (
                <span className="text-danger px-2">- invalid url</span>
              )}
            </label>
            <input
              type="text"
              id="videoUrl"
              className={`form-control ${invalidVideoUrl ? 'is-invalid' : ''}`}
              placeholder={
                addYoutubeVideo
                  ? 'e.g. https://www.youtube.com/watch?v=7AbZMeGJ5aA'
                  : 'e.g. https://www.instagram.com/reel/CrjWuiWAIL9'
              }
            />
          </div>
        )}

        <Card.Body className="px-2 pt-1 pb-2">
          {event && eventTiers.length > 0 && (
            <Tiers.Selector
              tiers={eventTiers}
              selection={tierIds}
              onSelect={updateTierIds}
            />
          )}
          <div className="float-right pb-1">
            <Button
              type="submit"
              disabled={loading || (incompletePoll && showPollComposer)}
              variant="primary"
            >
              Post
            </Button>

            <Dropdown id="moreOptionsPost" as={ButtonGroup} alignRight>
              <Dropdown.Toggle
                variant="outline-primary"
                className="composer-options"
                id="composer-options"
              >
                <MdAdd size={22} />
                <MdMoreHoriz size={22} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow-sm rounded-0">
                <Dropdown.Item onClick={handleAddPoll}>Add Poll</Dropdown.Item>
                <Dropdown.Item onClick={handleAddInstagramVideo}>
                  Add Instagram Video
                </Dropdown.Item>
                <Dropdown.Item onClick={handleAddYoutubeVideo}>
                  Add YouTube Video
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Body>
      </Form>

      <SMSpreview
        mode="create"
        show={smsPreview}
        closeModal={() => setSmsPreview(false)}
        postInput={postInput as PostInput}
        tierIds={tierIds}
        callback={() => {
          reset({ poll: undefined })
          updateTierIds([])
          setShowPollComposer(false)
          if (bodyRef.current) bodyRef.current.value = ''

          // reset video input
          setAddInstagramVideo(false)
          setAddYoutubeVideo(false)
          setInvalidVideoUrl(false)
        }}
      />
    </Card>
  )
}
export default Composer
