import React, { useState, useEffect } from 'react'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import uniqBy from 'lodash/fp/uniqBy'
import reduce from 'lodash/fp/reduce'
import toArray from 'lodash/fp/toArray'
import orderBy from 'lodash/fp/orderBy'
import groupBy from 'lodash/fp/groupBy'
import { useLazyQuery } from '@apollo/react-hooks'

import { GET_EVENT_BALANCES } from '../../../graphql'
import {
  getEventTotal,
  getComponentStatus,
  getHighestStatus,
} from '../eventFunctions'
import { offeringTypes } from '../../../common/constants'
import {
  IEventBuilder,
  EventComponent,
  Balance,
  Ibalances,
  EventComponentStatus,
} from '../../../types'

import {
  NegotiationEventCard,
  EventBuilderEventCard,
  CollapsedCard,
  CollapsedSlider,
} from './components'

export const EventBuilder: React.FC<IEventBuilder> = ({
  eventId,
  eventComponents,
  eventTiers,
}) => {
  const [balances, setBalances] = useState<Ibalances>()
  const [getEventBalances, { data }] = useLazyQuery(GET_EVENT_BALANCES, {
    fetchPolicy: 'network-only', // to keep the information as up to date as possible
  })
  const { Available } = EventComponentStatus
  const { roomOffering, packageOffering } = offeringTypes

  useEffect(() => {
    if (!data) {
      const providers = flow(
        uniqBy('offering.provider.id'),
        map((el: EventComponent) => el.offering.provider.id)
      )(eventComponents)

      getEventBalances({ variables: { eventId, providers } })
    } else if (!balances) {
      setBalances(
        flow(
          get('me.myEventInfo.balances'),
          reduce((accum: Ibalances, el: Balance) => {
            return {
              ...accum,
              [el.provider]: {
                balance: el.balance,
                total: getEventTotal(el.quotes),
                lastMessage: el.lastMessage,
                quotes: el.quotes,
              },
            }
          }, {})
        )(data)
      )
    }
  }, [balances, data, eventComponents, eventId, getEventBalances])

  const groupedByType = groupBy('offering.__typename', eventComponents)
  const packageComponents = groupedByType[packageOffering] || []
  const roomsByProvider = flow(
    get(`[${roomOffering}]`),
    groupBy('offering.provider.id'),
    toArray
  )(groupedByType)

  const list: EventComponent | EventComponent[] = orderBy(
    [
      el => {
        if (!balances) return false

        if (Array.isArray(el)) {
          // rooms
          return getHighestStatus({ eventComponents: el, balances })
        }
        // packages
        return getComponentStatus({ eventComponent: el, balances })
      },
    ],
    ['desc'],
    [...packageComponents, ...roomsByProvider]
  )

  return (
    <>
      {list.map(el => {
        if (Array.isArray(el)) {
          return (
            <CollapsedSlider
              key={el[0].id}
              eventTiers={eventTiers}
              eventComponents={el}
              eventId={eventId}
              balances={balances}
            />
          )
        }

        const { status } = el
        return (
          <CollapsedCard
            key={el.id}
            eventTiers={eventTiers}
            eventComponent={el}
            eventId={eventId}
            balances={balances}
          >
            {status !== Available && (
              <EventBuilderEventCard eventCardData={el} eventId={eventId} />
            )}
            {status === Available && (
              <NegotiationEventCard eventCardData={el} eventId={eventId} />
            )}
          </CollapsedCard>
        )
      })}
    </>
  )
}
