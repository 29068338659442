import React, { useState } from 'react'
import toInteger from 'lodash/fp/toInteger'
import { MdMoreHoriz } from 'react-icons/md'

import { FormatNumber } from '../../components'
import { getImageUrl, useIsUserAgent } from '../../common'
import { createOccupancyIcons } from '../../pages/Locations'
import { IRoomCard } from '../../types'

import { SingleRoomCard, SelectRoom } from './style'
import { OfferingModal, CreateEventComponentCTA } from './components'

export const RoomCard: React.FC<IRoomCard> = ({
  roomData,
  reservationData,
  className,
  myEventsRef,
  myEvents = [],
  validate,
  eventName = '',
}) => {
  const [error, setError] = useState<string>()
  const [showModal, setShowModal] = useState(false)
  const [added, setAdded] = useState(false)
  const [rooms, setRooms] = useState<number>()
  const isAgent = useIsUserAgent()

  const { price, mainImage, room, quantity = 0, marketPrice } = roomData
  const image = getImageUrl(mainImage, '400x250')
  const roomPrice = marketPrice || price

  const occupancy = toInteger(room.occupancy)
  const size = toInteger(room.size)

  const completed = () => {
    setAdded(true)
  }

  return (
    <>
      <OfferingModal
        data={roomData}
        show={showModal}
        setShowModal={setShowModal}
      />
      <SingleRoomCard className={`shadow-sm ${className || ''} venue-card`}>
        <div
          className="cover cursor"
          style={{ backgroundImage: `url("${image}")` }}
          onClick={() => setShowModal(true)}
        >
          <span className="cursor" onClick={() => setShowModal(true)}>
            <MdMoreHoriz color="white" size={30} />
          </span>
        </div>
        <h1 className="px-3 mb-2 fwb fz-18">{roomData.name}</h1>
        <div className="px-3 data position-relative pb-3">
          <p className="price">
            Price:{' '}
            {roomPrice === '0' ? (
              'upon request'
            ) : (
              <>
                <FormatNumber n={roomPrice} /> /nightly rate
              </>
            )}
          </p>
          {occupancy > 0 && (
            <div className="occupancy-cont">
              <p className="occupancy">OCCUPANCY</p>
              {createOccupancyIcons(occupancy)}
            </div>
          )}
          {size > 0 && (
            <p className={`size ${!isAgent && 'border-0 pb-0'}`}>
              Size: {size} sq. ft.
            </p>
          )}
          {isAgent && (
            <SelectRoom>
              <input
                type="number"
                max={quantity}
                min={1}
                className="rooms-selected"
                value={rooms}
                disabled={quantity <= 0}
                onFocus={() => {
                  if (!reservationData.date) {
                    validate()
                  }
                }}
                onChange={ev => {
                  const value = toInteger(ev.currentTarget.value)
                  if (value > quantity) {
                    setRooms(quantity)
                    setError(`limit exceeded (max: ${quantity})`)
                  } else {
                    setRooms(value)
                  }
                }}
              />
              <p className="fwsb my-0 mx-2">
                Select # of rooms
                <span className="d-block fwn">{quantity} available</span>
              </p>
            </SelectRoom>
          )}

          <CreateEventComponentCTA
            myEventsRef={myEventsRef}
            myEvents={myEvents}
            offering={roomData}
            input={{
              alias: null,
              capacity: toInteger(rooms),
              guestPays: 1,
              ...reservationData,
            }}
            completed={completed}
            eventName={eventName}
            added={added}
            error={error}
            setError={setError}
          />
        </div>
      </SingleRoomCard>
    </>
  )
}
